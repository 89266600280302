import { Component, ViewContainerRef,OnInit } from '@angular/core';
import { LoaderService } from './services/loader/loader.service';
import { Router, NavigationStart } from '@angular/router';

@Component({
  selector: 'app-root',
  template: `<app-header *ngIf="showHead"></app-header>
  
  <router-outlet (activate)="onActivate($event)">
  <span *ngIf="showLoader" class="loading"></span>
  </router-outlet>
  <app-ai-chat *ngIf="showAI"></app-ai-chat>
  
  `,
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  // public viewContainerRef: ViewContainerRef;
  // title = 'app';
  public  showLoader: boolean;
  showHead: boolean = false;
  showAI:boolean = false;
  constructor(private loaderService:LoaderService,private route: Router){
    route.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (event['url'] == '/login') {
          this.showHead = false;
          this.showAI = false;
        } else {
          // console.log("NU")
          this.showHead = true;
          this.showAI = true;
        }
      }
    });
  }
  onActivate(event) {
    window.scroll(0,0);
  }
  ngOnInit() {
    this.loaderService.status.subscribe((val: boolean) => {
      this.showLoader = val;
    });
    
    
  }
}
