import { Directive,  ElementRef, Input, OnChanges } from '@angular/core';
import * as D3 from 'd3';

@Directive({
  selector: '[appFollowerChart]'
})
export class FollowerChartDirective {
  @Input() chartFollowerData: any[];
  private host: D3.Selection<any, any, any, any>;
  private svg: D3.Selection<any, any, any, any>;
  private htmlElement: HTMLElement;
  private width: number;
  private height: number;
  private margin: { top: number; right: number; bottom: number; left: number };
  private minBarWidth = 5;
  private totalWidth: number;


  constructor(private el: ElementRef) { 
    this.htmlElement = this.el.nativeElement;
    this.host = D3.select(this.htmlElement);
  }

  ngOnChanges() {
    this.setup();
    this.buildSVG();
    if (this.chartFollowerData) {
      this.updateChart();
    }
  }

  private setup(): void {
    this.margin = { top: 20, right: 20, bottom: 40, left: 40 };
    this.width = 630 - this.margin.left - this.margin.right;
    this.height = 300 - this.margin.top - this.margin.bottom;

    this.totalWidth = Math.max(
      this.width,
      this.chartFollowerData.length * (this.minBarWidth + 10)
    );
  }

  private buildSVG(): void {
    this.host.html('');
    this.svg = this.host.append('svg')
      .attr('width', this.totalWidth + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .append('g')
      .attr('transform', `translate(${this.margin.left},${this.margin.top})`);
  }

  private updateChart() {
    // Clear previous chart
    this.svg.selectAll('*').remove();

    // Set up scales
    const x = D3.scaleBand()
      .range([0, this.totalWidth])
      .padding(0.1);

    const y = D3.scaleLinear()
      .range([this.height, 0]);

    x.domain(this.chartFollowerData.map(d => d.month));
    y.domain([0, D3.max(this.chartFollowerData, d => d.value)]);

    const maxBarWidth = this.width - 400;
    const barWidth = this.chartFollowerData.length === 1 ? maxBarWidth : x.bandwidth();

    // Draw bars
    this.svg.selectAll('.bar')
      .data(this.chartFollowerData)
      .enter().append('rect')
      .attr('class', 'bar')
      .attr('x', d =>
        this.chartFollowerData.length === 1
          ? (this.width - barWidth) / 2
          : x(d.month)
      )
      .attr('width', barWidth)
      .attr('y', d => y(d.value))
      .attr('height', d => this.height - y(d.value))
      .attr('fill', '#1baba2') // Change color for followers graph
      .append('title') // Add tooltip
      .text(d => `${d.month}\nFollowers: ${d.value}`);

    // Add x-axis
    this.svg.append('g')
      .attr('transform', `translate(0,${this.height})`)
      .call(D3.axisBottom(x))
      .selectAll('text')
      .attr('transform', 'rotate(-40)')
      .style('text-anchor', 'end');

    // Add y-axis
    this.svg.append('g')
      .call(D3.axisLeft(y));

    // Add y-axis label
    this.svg.append('text')
      .attr('class', 'y label')
      .attr('text-anchor', 'end')
      .attr('x', -6)
      .attr('y', 30)
      .attr('dy', '-5.7em')
      .attr('dx', '-1em')
      .attr('transform', 'rotate(-90)')
      .text('Number of Followers');
  }


}
