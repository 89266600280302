import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { Ng2Webstorage } from 'ngx-webstorage';
import { ModalModule, BsDropdownModule } from 'ngx-bootstrap';
import { Daterangepicker } from 'ng2-daterangepicker';
import { MomentModule } from 'ngx-moment';
import { NgxPaginationModule } from 'ngx-pagination';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ToastrModule } from 'ngx-toastr';
import {ImageCropperModule} from "ng2-img-cropper/index";

import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { ForgotPasswordComponent } from './components/login/forgot-password/forgot-password.component';
import { MainComponent } from './components/main/main.component';
import { HomeComponent } from './components/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { SuccessrateService } from './services/successrate/successrate.service';
import { MessageServiceService } from './services/message-service/message-service.service';
import { ReportService } from './services/report/report.service';
import { DataAnalysisService } from './services/data-analysis/data-analysis.service';
import { LoaderService } from './services/loader/loader.service';
import { MappingService } from './services/mapping/mapping.service';
import {BrandService} from './services/brand/brand.service';
import{CompanyService} from './services/company/company.service';
import{DashboardService} from './services/dashboard/dashboard.service';
import{HomeService} from './services/home/home.service';
import {MarketplacesService} from './services/marketplace-dashboard/marketplaces.service';
import{SocialmediaService} from './services/socialmedia-dashboard/socialmedia.service';
import{StandaloneWebsitesService} from './services/standalone-websites-dashboard/standalone-websites.service';
import{UserService} from './services/user/user.service';
import { ChatService } from './services/chat/chat.service';



import { routing } from './app.routing';
import { AuthService } from './services/auth/auth.service';
import { LoggedInGuard } from './providers/auth/auth.providers';
import { D3Service } from 'd3-ng2-service';
import { HomeDashboardComponent } from './components/home/home-dashboard/home-dashboard.component';
import { HomeReportComponent } from './components/home/home-report/home-report.component';
import { HomeToolsComponent } from './components/home/home-tools/home-tools.component';
import { HomeMappingComponent } from './components/home/home-mapping/home-mapping.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SuccessRateMarketplaceComponent } from './components/success-rate-marketplace/success-rate-marketplace.component';
import { SuccessRateSocialMediaComponent } from './components/success-rate-social-media/success-rate-social-media.component';
// tslint:disable-next-line:max-line-length
import { SuccessRateStandaloneWebsitesComponent } from './components/success-rate-standalone-websites/success-rate-standalone-websites.component';
import { PiechartDirective } from './directives/piechart/piechart.directive';
import { BarchartDirective } from './directives/barchart/barchart.directive';
import { WorldmapDirective } from './directives/worldmap/worldmap.directive';
// import { SelectModule } from 'ng2-select';
import { BootstrapSelectDirective } from './directives/bootstrap-select/bootstrap-select.directive';
import { MarketplaceDashboardComponent } from './components/marketplace-dashboard/marketplace-dashboard.component';
import { SocialmediaDashboardComponent } from './components/socialmedia-dashboard/socialmedia-dashboard.component';
import { StandaloneWebsitesDashboardComponent } from './components/standalone-websites-dashboard/standalone-websites-dashboard.component';
import { StackedbarDirective } from './directives/stackedbar/stackedbar.directive';
import { ScatterPlotDirective } from './directives/scatter-plot/scatter-plot.directive';
import { BubbleChartDirective } from './directives/bubble-chart/bubble-chart.directive';
import { GuagebarDirective } from './directives/guagebar/guagebar.directive';
import { ProfileComponent } from './components/profile/profile.component';
import { ReportComponent } from './components/report/report.component';
import { DataAnalysisComponent } from './components/data-analysis/data-analysis.component';
import { RelationgraphDirective } from './directives/relationgraph/relationgraph.directive';
import { MappingComponent } from './components/mapping/mapping.component';
import { ThousandSuffixPipe } from './pipe/thousandSuffix/thousand-suffix.pipe';
import { MultilinechartDirective } from './directives/multilinechart/multilinechart.directive';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTabsModule }    from '@angular/material/tabs';
import { MatButtonModule, MatCheckboxModule } from '@angular/material';
import { PackedcircleDirective } from './directives/packed-circle/packedcircle.directive';
import { NgSelectModule } from '@ng-select/ng-select';
import { AiChatComponent } from './components/ai-chat/ai-chat.component';
import { LikeChartDirective } from './directives/likeChart/like-chart.directive';
import { SellerMapComponent } from './components/data-analysis/seller-map/seller-map.component';
import { FollowerChartDirective } from './directives/followerChart/follower-chart.directive';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    HeaderComponent,
    ForgotPasswordComponent,
    MainComponent,
    HomeDashboardComponent,
    HomeReportComponent,
    HomeToolsComponent,
    HomeMappingComponent,
    DashboardComponent,
    SuccessRateMarketplaceComponent,
    SuccessRateSocialMediaComponent,
    SuccessRateStandaloneWebsitesComponent,
    PiechartDirective,
    BarchartDirective,
    WorldmapDirective,
    BootstrapSelectDirective,
    MarketplaceDashboardComponent,
    SocialmediaDashboardComponent,
    StandaloneWebsitesDashboardComponent,
    StackedbarDirective,
    ScatterPlotDirective,
    BubbleChartDirective,
    GuagebarDirective,
    ProfileComponent,
    ReportComponent,
    DataAnalysisComponent,
    RelationgraphDirective,
    MappingComponent,
    ThousandSuffixPipe,
    MultilinechartDirective,  
    PackedcircleDirective, 
    AiChatComponent, 
    LikeChartDirective,
    SellerMapComponent,
    FollowerChartDirective
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpModule,
    HttpClientModule,
    BrowserAnimationsModule,
    Ng2Webstorage,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    routing,
    Daterangepicker,
    MomentModule,
    // SelectModule,
    NgxPaginationModule,
    ToastrModule.forRoot(),
    ImageCropperModule,
    CarouselModule.forRoot(),
    AngularMultiSelectModule,
    NgMultiSelectDropDownModule,
    MatNativeDateModule,    
    MatTabsModule,
    MatButtonModule,
    MatCheckboxModule,
    NgSelectModule
  ],
  entryComponents: [
    ForgotPasswordComponent
  ],
  providers: [AuthService,
   LoggedInGuard, 
   D3Service,
    SuccessrateService,
    MessageServiceService,
    ReportService,
    DataAnalysisService,
    MappingService,
    LoaderService,
    BrandService,
    CompanyService,
    DashboardService,
    HomeService,
    MarketplacesService,
    SocialmediaService,
    StandaloneWebsitesService,
    UserService,
    ChatService
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
