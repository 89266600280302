import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { DashboardService } from '../../services/dashboard/dashboard.service';
import { LocalStorageService } from 'ngx-webstorage';
import { AppSettings } from '../../settings.global';
import { BrandService } from '../../services/brand/brand.service';
import { Observable, fromEvent, interval } from 'rxjs/';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import {DatePipe} from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { LoaderService } from '../../services/loader/loader.service';
import { AuthService } from '../../services/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx';
import { TitleCasePipe } from '@angular/common';

@Component({
  selector: 'app-socialmedia-dashboard',
  templateUrl: './socialmedia-dashboard.component.html',
  styleUrls: ['./socialmedia-dashboard.component.scss'],
  providers: [DatePipe,TitleCasePipe]
})
export class SocialmediaDashboardComponent implements OnInit {

@ViewChild('scroller') scroller: ElementRef; 
@ViewChild('printSection') table: ElementRef;


  modalRef: BsModalRef;
  modalRef2: BsModalRef;

  daterange: any = {
    start: moment().startOf('year').format('YYYY-MM-DD'),
    end : moment().format('YYYY-MM-DD')
  };

  public datepickerOptions: any = {
    // startDate: moment().startOf('year'),
    // endDate: moment(),
    minDate: '01/01/2000',
    maxDate: moment(),
    showDropdowns: !0,
    showWeekNumbers: !0,
    timePicker: !1,
    timePickerIncrement: 1,
    timePicker12Hour: !0,
    ranges: {
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Quarter': [moment().quarter(moment().quarter()).startOf('quarter'), moment()],
      'Since January 1st': [moment().startOf('year'), moment()],
      // '1st of the Month' :[moment().startOf('month') , moment()]
    },
    opens: 'left',
    buttonClasses: ['btn btn-default'],
    applyClass: 'btn-small btn-primary',
    cancelClass: 'btn-small',
    format: 'MM/DD/YYYY',
    separator: ' to ',
    locale: {
      applyLabel: 'Submit',
      cancelLabel: 'Clear',
      fromLabel: 'From',
      toLabel: 'To',
      customRangeLabel: 'Time Range',
      daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      // tslint:disable-next-line:max-line-length
      monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      firstDay: 1
    },
    applyOnMenuSelect: true
  };  
  public chartOption: object;
  public motherCompany : any;
  public mCompanyId : any;
  public mCompanySelector : any;
  public socialMedia : any;
  public socailMediaId : any;
  public dashData : any;
  public localData : any;
  public queryLimit: number = AppSettings.DEFAULT_QUERY_LIMIT;
  public offSet: number = 0;
  public apiCallCount: number = 0;
  public successData : any;
  public reported : any;
  public disabled : any;
  public successRate: any;
  public breakdown : any;
  public pieData1 : any;
  public labelData: any;
  public status : any;
  public pieData2 : any;
  public pager:any;
  public itemPerPageCount: number = AppSettings.DEFAULT_PAGER_COUNT;
  public img_url = AppSettings.IMAGE_BASE_URL;
  public screenshot_url = AppSettings.SCREENSHOT_BASE_URL
  public listData : any;
  public colors : any;
  public numberOfPages: any;
  public start: any;
  public end: any;
  public no_of_entry: any;
  public searchInputObservable$: any;
  public copyValue: any;
  public screenShots: any;
  public activeIndex: any;
  public errorMsg = AppSettings.Session_Expire_Message;
  public errorDscrp = AppSettings.Session_Expire_Description;
  public mcompany_exists:boolean=false;
  public lastDate : any;
  public isSingleClick : boolean;  
  public rangeMonthTitle: any = '1st of ' + moment().format("MMMM");
  public pageBeforeSearch: any;
  public selectedDateRangeLabel: any;
  public selected_brand_list_data: any;
  public breakdown_selected_brand_list_data :any;
  public status_selected_brand_list_data :any;
  public statusLabel:any;

  constructor(private dashboardService: DashboardService,
    private lsService: LocalStorageService,
    public datepipe: DatePipe,
    private brandService: BrandService,
    private loaderService:LoaderService,
    private authServiceObj:AuthService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private titleCasePipe:TitleCasePipe) { 
      this.selectedDateRangeLabel = this.lsService.retrieve('seleceddatelabel');  
    this.mCompanySelector = {
      id : '',
      type : 'social_media',
      is_mcommerce : ''
    }
    this.socailMediaId = '';
    this.socialMedia = [];
    this.motherCompany = [];
    this.localData = JSON.parse(this.lsService.retrieve('search_base'));
    this.dashData = {
      brand : this.lsService.retrieve('active_brand_id'),
      mother_company : '',
      social_media : '',
      fromDate : this.localData.fromDate,
      toDate : this.localData.toDate,
      searchkey: "",
      orderby: "",
      order: "",
      limit: this.queryLimit,
      offset: 0
    };
    var selectedBrandsDetails = [];

    // selectedBrandsDetails = JSON.parse(this.lsService.retrieve('selectedBrands'));
    var unsortedSelectedBrandsDetails = JSON.parse(this.lsService.retrieve('selectedBrands'));  
    //Sort the list of brands selected
    selectedBrandsDetails = unsortedSelectedBrandsDetails.sort((a, b) => (a.name > b.name) ? 1 : -1) 
    
    if(!selectedBrandsDetails || selectedBrandsDetails == null)
    {
      selectedBrandsDetails = [];
    }


    // var selectedSummaryObj = {id:0, name:'All Selected',spcialClass:'active-brands-tab'};
    // if(selectedBrandsDetails.length > 1)
    // {
    //   selectedBrandsDetails.unshift(selectedSummaryObj);
    // }
    // if(selectedBrandsDetails.length == 1)
    // {
    //   selectedBrandsDetails[0].spcialClass = 'active-brands-tab';
    // }
    // this.selected_brand_list_data = selectedBrandsDetails;

    //-----------------------Edited--------------------
    var breakdown_selectedSummaryObj = {id:0, name:'All Breakdown',spcialClass:'active-brands-tab '};
    var status_selectedSummaryObj = {id:0, name:'Status',spcialClass:'active-brands-tab '};
    // Deep clone
    var breakdown_selectedBrandsDetails = JSON.parse(JSON.stringify(selectedBrandsDetails));
    var status_selectedBrandsDetails = JSON.parse(JSON.stringify(selectedBrandsDetails));

    if(selectedBrandsDetails.length >= 1)
    {
      breakdown_selectedBrandsDetails.unshift(breakdown_selectedSummaryObj);
      status_selectedBrandsDetails.unshift(status_selectedSummaryObj);
    }   
    this.breakdown_selected_brand_list_data = breakdown_selectedBrandsDetails;
    this.status_selected_brand_list_data = status_selectedBrandsDetails;

    //-----------------------Edited--------------------
    this.daterange.start = this.localData.fromDate;
    this.daterange.end = this.localData.toDate;
    this.successData = [];
    this.reported = 0;
    this.disabled = 0;
    this.successRate = 0;
    this.breakdown = [];
    this.pieData1 = [];
    this.statusLabel = [{name:'ON'},{name:'OFF'}];
    this.labelData = [];
    this.status = [];
    this.pieData2 = [];
    this.pager = {
      limit: this.itemPerPageCount,
      offset: 0,
      total_count: 0,
      current_page: 1
    };
    this.listData = [];
   // this.colors = ['#464646', '#838383', '#858d93', '#a0a7ab', '#c2c2c1', '#d6d9db']; 
  //  this.colors = ['#303030','#404040','#505050','#606060','#696969','#808080'];
    this.colors = ['#c75ac7','#ee82ee','#8d5ba7','#ee82d3','#a682ee','#745ba7'];
    this.no_of_entry = 50;
    this.screenShots = [];
    this.activeIndex = 0;
    // Current month option added in range options
    this.datepickerOptions.ranges[this.rangeMonthTitle]=[moment().startOf('month') , moment()]
    
    // let fromDateLocal =  this.localData.fromDate.split('-');
    // let formattedFromDateLocal = fromDateLocal[1]+'-'+fromDateLocal[2]+'-'+fromDateLocal[0];
    // let toDateLocal =  this.localData.toDate.split('-');
    // let formattedtoDateLocal = toDateLocal[1]+'-'+toDateLocal[2]+'-'+toDateLocal[0];
    // this.datepickerOptions.startDate =  formattedFromDateLocal;
    // this.datepickerOptions.endDate = formattedtoDateLocal;   
    this.datepickerOptions.startDate =  new Date(this.localData.fromDate);
    this.datepickerOptions.endDate = new Date(this.localData.toDate);     
    console.log('this.datepickerOptions.startDate--',this.datepickerOptions.startDate)  
    console.log('this.datepickerOptions.endDate--',this.datepickerOptions.endDate) 

  }

  ngOnInit() {
    // this.loaderService.display(true);
    // this.brandService.brandChanged.subscribe((id) => {
      //   this.loaderService.display(false);
      //   this.dashData.brand = id;
      //   this.apiCallCount = 0;
      //   this.pager["total_count"] = 0;
      //   this.pager["current_page"] = 1;
      //   this.pager["offset"] = 0;
      //   this.offSet = 0;
      //   this.listData = [];
      //   this.getSocialMediaData(this.dashData,1);
      // });
      this.apiCallCount = 0;
      this.pager["total_count"] = 0;
      this.pager["current_page"] = 1;
      this.pager["offset"] = 0;
      this.offSet = 0;
      this.listData = [];
      //this.getSocialMediaData(this.dashData,1);
      this.loaderService.display(true);
      this.dashboardService.getMotherCompany('social_media',this.dashData.brand,this.dashData.fromDate,this.dashData.toDate).subscribe( (res) =>{
        //this.loaderService.display(false);        
        if(res.json().status == "success"){
          this.motherCompany = res.json().data_found;
          for(let mcompany of this.motherCompany){
                if(mcompany.id==this.lsService.retrieve('top_social_media')){
                  this.mcompany_exists=true;
                }
              }
              if(this.mcompany_exists){
                this.mCompanyId = (this.lsService.retrieve('top_social_media'))?this.lsService.retrieve('top_social_media'):0;
                this.mCompanySelector.id = (this.lsService.retrieve('top_social_media'))?this.lsService.retrieve('top_social_media'):0;
              
              }else{
                this.mCompanyId =0 ;
                this.mCompanySelector.id = 0
              }
              this.lsService.store('top_social_media', this.mCompanyId);

              this.mCompanySelector.from_date=this.localData.fromDate;
              this.mCompanySelector.to_date=this.localData.toDate;
              this.mCompanySelector.brand_ids=this.dashData.brand;

           //this.loaderService.display(true);
          this.dashboardService.getMotherCompanyExt(this.mCompanySelector).subscribe((res) => {
            //this.loaderService.display(false);
            this.socialMedia = res.json().data_found;
            this.socailMediaId=(this.lsService.retrieve('top_social_media_sub'))?this.lsService.retrieve('top_social_media_sub'):'';
            this.dashData.social_media =this.socailMediaId;
          });
          this.dashData['mother_company'] = this.mCompanySelector.id;
          this.getSocialMediaData(this.dashData,1);
        }else{
          this.mCompanyId =0;
          this.dashData['mother_company'] =0;
          this.pieData1=null;
          this.pieData2=null;
          this.lsService.store('top_social_media', this.mCompanyId);
          this.loaderService.display(false);
          if(res.json().message=="invalid_accesstoken"||res.json().message=="un_authorised"){
            //this.toastr.error(this.errorDscrp,this.errorMsg);
            this.authServiceObj.logout(true);
          }
        }

      });
        
        //Scrolling the top scroll bar while bottom one is scrolling and vice vrersa.
        $(function(){
          $(".scroll-main").scroll(function() {              
              $(".scrollDummy").scrollLeft($(".scroll-main").scrollLeft());
          });

          $(".scrollDummy").scroll(function(){                
              $(".scroll-main").scrollLeft($(".scrollDummy").scrollLeft());
          });
        });

        setTimeout(function(){      
          var coverWidth = document.getElementById('sm_brndListCoverMain').offsetWidth; 
          var contentWidth = document.getElementById('ListingBrands').offsetWidth;
          if(contentWidth <= coverWidth)
          {
            var all = document.getElementsByClassName('brnadlist-slider-icon');
            for (var i = 0; i < all.length; i++) {
              all[i].className += " hide-content"; 
            }
          }
        }, 500);

    }

    mCompanyChange(id){
      this.lsService.store('top_social_media', id);
         
      this.mCompanySelector ={
        id : id,
        type : 'social_media',
        is_mcommerce : 'false'
      }
      this.mCompanySelector.from_date=this.localData.fromDate;
      this.mCompanySelector.to_date=this.localData.toDate;
      this.mCompanySelector.brand_ids=this.dashData.brand;
      this.dashData['mother_company'] = id;
      this.dashData.social_media = '';
      this.loaderService.display(true);
      this.dashboardService.getMotherCompanyExt(this.mCompanySelector).subscribe((res) => {
        this.loaderService.display(false);
        if(res.json().status == "success"){
          this.socialMedia = res.json().data_found; 
          this.socailMediaId = ''; 
          this.apiCallCount = 0;
          this.pager["total_count"] = 0;
          this.pager["current_page"] = 1;
          this.pager["offset"] = 0;
          this.offSet = 0;
          this.listData = [];  
          this.getSocialMediaData(this.dashData,1);
        }else{
          if(res.json().message == 'no_data_found'){
            this.socialMedia = [];
            this.socailMediaId = ''; 
            this.apiCallCount = 0;
            this.pager["total_count"] = 0;
            this.pager["current_page"] = 1;
            this.pager["offset"] = 0;
            this.offSet = 0;
            this.listData = [];  
            this.getSocialMediaData(this.dashData,1);
          }
          if(res.json().message=="invalid_accesstoken"||res.json().message=="un_authorised"){
            //this.toastr.error(this.errorDscrp,this.errorMsg);
            this.authServiceObj.logout(true);
          }
        }

      })
    }

    getSocialMediaData(data,page: number){
      this.lsService. store('search_base', JSON.stringify(this.localData));
      this.dashData['offset'] = this.offSet;
      //console.log(this.dashData)
      this.loaderService.display(true);      
      this.dashboardService.getSocialMediaData(this.dashData).subscribe( (res) => {

        this.apiCallCount++;      
        this.successData = res.json().success_rate;
        this.reported = this.successData.reported_link;
        this.disabled = this.successData.disabled_link;
        this.successRate = Math.round(this.successData.success_rate);
        this.breakdown = res.json().social_media_breakdown;
        //console.log(res.json().social_media_list.data,res.json().social_media_list.data.length,"eeeeeeeee")
        if(res.json().social_media_list.status == 'success' && !_.isNull(res.json().social_media_list.data)){
          this.pager["total_count"] = this.listData.length + res.json().social_media_list.data.length;
          this.pager["current_page"] = page;
          const result = this.listData.concat(res.json().social_media_list.data);
          this.listData = result;
          console.log("The result data is: ",this.listData,this.listData.length)
          const totalItemsCount = this.pager["total_count"];
          const numberOfItemsPerPage = this.pager["limit"];
          const pages =this.pager["current_page"];
          this.numberOfPages = totalItemsCount;
          this.start = (pages * parseInt(numberOfItemsPerPage)) - (parseInt(numberOfItemsPerPage) - 1);
          //console.log(this.listData.length,"data le")
          this.end = Math.min(this.start + parseInt(numberOfItemsPerPage) - 1, totalItemsCount);
          if(!_.isNil(res.json().social_media_list.max_possible_res_length)&& !_.isNull(res.json().social_media_list.data) && res.json().social_media_list.max_possible_res_length > this.listData.length+1) {
            this.offSet = this.apiCallCount * this.queryLimit;
            this.getSocialMediaData(this.dashData,1);
          }else{
            this.loaderService.display(false);
            this.pieData1 = res.json().social_media_breakdown.data.map(val => val.reported );
            if(this.pieData1.length == 0){
              this.pieData1 = null;
            }
            this.labelData = res.json().social_media_breakdown.data;      
            if(this.reported != 0 || this.disabled != 0){
              this.status = ['ON', 'OFF'];
              this.pieData2 = [(this.reported - this.disabled), this.disabled];  
            } else{
              this.status = [];
              this.pieData2 = null;
              this.reported = 0;
              this.disabled = 0; 
            }
          }    
        }else{
          this.loaderService.display(false);
           if(this.listData.length === 0 || res.json().social_media_list.max_possible_res_length === 0) {
            this.pieData1 = null;
            this.status = [];
            this.pieData2 = null;
            this.reported = 0;
            this.disabled = 0;
            this.listData = [];
            this.pager["total_count"] = 0;
            this.pager["current_page"] = page;
            const totalItemsCount = this.pager["total_count"];
            const numberOfItemsPerPage = this.pager["limit"];
            const pages =this.pager["current_page"];
            this.numberOfPages = totalItemsCount;
            this.start = (pages * parseInt(numberOfItemsPerPage)) - (parseInt(numberOfItemsPerPage) - 1);
            this.end = Math.min(this.start + parseInt(numberOfItemsPerPage) - 1, totalItemsCount);
          }
          if(res.json().social_media_list.message == "invalid_accesstoken" || res.json().social_media_list.message == "un_authorised"){
            //this.toastr.error(this.errorDscrp,this.errorMsg);
            this.authServiceObj.logout(true);
          }
        }        
      })
    }
     // function for scroll brands tab
    //Added By : Arif
    slideBrand(position:any, type:any)
    {
      //alert(type);
      const conent = document.querySelector('#'+type+'brndListCoverMain');
      if(position == 'right') conent.scrollLeft += 100;
      if(position == 'left') conent.scrollLeft -= 100;
      event.preventDefault();
      //alert(position);
    }
    // function to display chart for markrt places based on tabs selected
    //Added By : Arif
    viewBrandsTabData(index:any, type:string){
      var previousSelectedId = document.querySelector("."+type+"active-brands-tab").id;
      //alert(type+'selectedBrand_'+index);
      document.getElementById(previousSelectedId).classList.remove(type+"active-brands-tab","active-brands-tab");
      document.getElementById(type+'selectedBrand_'+index).classList.add(type+"active-brands-tab","active-brands-tab");
      
      // Section to change chart content
      this.lsService.store('search_base', JSON.stringify(this.localData));
      this.dashData['offset'] = this.offSet;
      this.loaderService.display(true); 
      
      var apiRequestData = this.dashData;
      if(index != 0)
      {
        apiRequestData.brand = [index];
      }
      else{
        apiRequestData.brand = this.lsService.retrieve('active_brand_id');
      }
      //console.log('Dash Data', apiRequestData);
      this.dashboardService.getSocialMediaData(apiRequestData).subscribe( (res) => {
        // this.successData = res.json().success_rate;
        // this.reported = this.successData.reported_link;
        // this.disabled = this.successData.disabled_link;
        let reportedCount = res.json().success_rate.reported_link;
        let disabledCount = res.json().success_rate.disabled_link;

        this.loaderService.display(false);
        if(type == 'sa_')
        {
          this.pieData1 = res.json().social_media_breakdown.data.map(val => val.reported );
          this.labelData = res.json().social_media_breakdown.data; 
          if(this.pieData1.length == 0){
            this.pieData1 = null;
          }
        }
        if(type == 'st_')
        {
          // this.labelData = res.json().social_media_breakdown.data;      
          // if(this.reported != 0 || this.disabled != 0){
          if(reportedCount != 0 || disabledCount != 0){
            this.status = ['ON', 'OFF'];
            this.pieData2 = [(reportedCount - disabledCount), disabledCount];  
          } else{
            this.status = [];
            this.pieData2 = null;
            // this.reported = 0;
            // this.disabled = 0;
          }
        }
             
      })
    
    }
    
    // Highlight legent and corresponding count in breakdown chart
  customizeBreakdownChart( selection,  pieData ) {  
    selection.select('path')
              .attr('stroke-width', 2)
              .attr('stroke', '#fff');
    selection.append('title')
      // .text((datum, index) => (pieStatus[index]) + ' : ' + pieData[index]);            
      .text((datum, index) => this.titleCasePipe.transform(pieData[index].social_media_name) + ' : ' + pieData[index].reported);          
  }
  // Highlight legent and corresponding count in status chart
  customizeStatusChart( selection, pieLabel,pieData ) {   
    selection.select('path')
              .attr('stroke-width', 2)
              .attr('stroke', '#fff');
    selection.append('title')
      // .text((datum, index) => (pieStatus[index]) + ' : ' + pieData[index]);            
      .text((datum, index) => this.titleCasePipe.transform(pieLabel[index]) + ' : ' + pieData[index]);          
  }
  onBreakDownChartUpdate(selection) {              
    this.customizeBreakdownChart(selection, this.labelData);    
  }
  onStatusChartUpdate(selection) {     
    this.customizeStatusChart(selection,this.status,this.pieData2);    
  }


    onPageChange(page: number) {
      console.log(page)
      this.pager["current_page"] = page;
      this.pager["offset"] = this.itemPerPageCount * (page - 1);
      const totalItemsCount = this.pager["total_count"];
      const numberOfItemsPerPage = this.pager["limit"];
      const pages =this.pager["current_page"];
      this.numberOfPages = totalItemsCount;
      this.start = (pages * parseInt(numberOfItemsPerPage)) - (parseInt(numberOfItemsPerPage) - 1);
      this.end = Math.min(this.start + parseInt(numberOfItemsPerPage) - 1, totalItemsCount);
    }

    socialMediaChange(id){
      this.lsService.store('top_social_media_sub', id);
      this.dashData.social_media = id;
      this.apiCallCount = 0;
      this.pager["total_count"] = 0;
      this.pager["current_page"] = 1;
      this.pager["offset"] = 0;
      this.offSet = 0;
      this.listData = [];
      this.getSocialMediaData(this.dashData,1);
    }
    onSearch2() {
      let page = 1;
      this.pageBeforeSearch = this.pager["current_page"];    
      this.listData = [];
      this.apiCallCount = 0;
      this.offSet = 0; 
      this.pager["total_count"] = 0;
      this.pager["current_page"] = 1;
      this.pager["offset"] = 0; 
      this.lsService. store('search_base', JSON.stringify(this.localData));
      this.dashData['offset'] = this.offSet;
      this.loaderService.display(true);  

      this.dashboardService.getSocialMediaData(this.dashData).subscribe( (res) => {
      this.apiCallCount++;           
        
        if(res.json().social_media_list.status == 'success' && !_.isNull(res.json().social_media_list.data)){
          this.pager["total_count"] = this.listData.length + res.json().social_media_list.data.length;
          this.pager["current_page"] = page;

          const result = this.listData.concat(res.json().social_media_list.data);
          this.listData = result;
         
          const totalItemsCount = this.pager["total_count"];
          const numberOfItemsPerPage = this.pager["limit"];
          const pages =this.pager["current_page"];

          this.numberOfPages = totalItemsCount;
          this.start = (pages * parseInt(numberOfItemsPerPage)) - (parseInt(numberOfItemsPerPage) - 1);         
          this.end = Math.min(this.start + parseInt(numberOfItemsPerPage) - 1, totalItemsCount);
          
          if(!_.isNil(res.json().social_media_list.max_possible_res_length)&& !_.isNull(res.json().social_media_list.data) && res.json().social_media_list.max_possible_res_length > this.listData.length+1) {
            this.offSet = this.apiCallCount * this.queryLimit;           
          }else{
            this.loaderService.display(false);            
          }    
        }else{
          this.loaderService.display(false);
           if(this.listData.length === 0 || res.json().social_media_list.max_possible_res_length === 0) {
            
            this.listData = [];
            this.pager["total_count"] = 0;
            this.pager["current_page"] = page;

            const totalItemsCount = this.pager["total_count"];
            const numberOfItemsPerPage = this.pager["limit"];
            const pages =this.pager["current_page"];
            this.numberOfPages = totalItemsCount;

            this.start = (pages * parseInt(numberOfItemsPerPage)) - (parseInt(numberOfItemsPerPage) - 1);
            this.end = Math.min(this.start + parseInt(numberOfItemsPerPage) - 1, totalItemsCount);
          }
          if(res.json().social_media_list.message == "invalid_accesstoken" || res.json().social_media_list.message == "un_authorised"){
            //this.toastr.error(this.errorDscrp,this.errorMsg);
            this.authServiceObj.logout(true);
          }
        }        
      })

    }

    onSearch(){
      this.pageBeforeSearch = this.pager["current_page"];    
      this.listData = [];
      this.apiCallCount = 0;
      this.offSet = 0; 
      this.pager["total_count"] = 0;
      this.pager["current_page"] = 1;
      this.pager["offset"] = 0;   
      this.getSocialMediaData(this.dashData,1);
    }

    clearSearch() {      
      this.dashData.searchkey='';
      this.listData = [];
      this.apiCallCount = 0;
      this.offSet = 0; 
      this.pager["total_count"] = 0;
      this.pager["current_page"] = 1;
      this.pager["offset"] = 0;   
      // this.getSocialMediaData(this.dashData,1);
      this.getSocialMediaData(this.dashData,this.pageBeforeSearch); 
    }

    ngAfterContentInit() {
      //Input search observable - to avoid the  onInputChange() firing whenever the input changes
      const searchInputObj = document.querySelector('#search-table');
      this.searchInputObservable$ = fromEvent(searchInputObj, 'keyup').throttleTime(1000);
      this.loaderService.display(true);
      // const secondsCounter = interval(12000);
      // secondsCounter.subscribe(n =>{
        // this.searchInputObservable$.subscribe((event: any) => {   
        //   this.loaderService.display(false);    
        //   this.onInputChange(event.target.value);
        // });
        //});

    }

    ngAfterViewChecked() {     
    let mpTableWidth = $('.sm-table').width();
    // console.log('ngAfterViewChecked-',mpTableWidth);
    $('.scrollDummy div').width(mpTableWidth);   
    }

      onInputChange(text) {
        this.onSearch();
      }

      entryNoChange(value:number){
        this.listData = [];
        this.apiCallCount = 0;
        this.offSet = 0; 
        // this.dashData.limit = value;
        this.pager["limit"] = value;
        this.pager["total_count"] = 0;
        this.pager["current_page"] = 1;
        this.pager["offset"] = 0;
        this.getSocialMediaData(this.dashData,1);     
      }

      onSort(orderby: string, id: any) {
        if (this.dashData["orderby"] === orderby) {
          if (this.dashData["order"] === "asc") {
            this.dashData["order"] = "desc";
          } else {
            this.dashData["order"] = "asc";
          }
        } else {
          this.dashData["orderby"] = orderby;
          this.dashData["order"] = "desc"
        }
        //Reset the page to 1, offset to 0
        this.pager["current_page"] = 1;
        this.pager["offset"] = 0;
        this.listData = this.dashboardService.sortListData(this.listData, this.dashData["orderby"], this.dashData["order"]);
      }

      //Change date range
      selectedDate(value: any) {
        this.selectedDateRangeLabel = value.label;   
        this.lsService.store('selecedDateLabel',this.selectedDateRangeLabel);  
        
        this.datepickerOptions.startDate =  moment(value.start._d).format('YYYY-MM-DD');
        this.datepickerOptions.endDate = moment(value.end._d).format('YYYY-MM-DD');
        
        this.dashData.fromDate =  this.datepickerOptions.startDate;
        this.dashData.toDate = this.datepickerOptions.endDate;
               this.apiCallCount = 0;
        this.pager["total_count"] = 0;
        this.pager["current_page"] = 1;
        this.pager["offset"] = 0;
        this.offSet = 0;
        this.listData = [];
        this.localData.fromDate = this.datepickerOptions.startDate;
        this.localData.toDate = this.datepickerOptions.endDate;

        // this.searchBase.fromDate = this.datepickerOptions.startDate ;
        // this.searchBase.toDate = this.datepickerOptions.endDate;
        
        this.getSocialMediaData(this.dashData,1);

        this.lastDate = this.datepickerOptions.endDate;        

        // this.daterange.start = moment(value.start._d).format('YYYY-MM-DD');
        // this.daterange.end = moment(value.end._d).format('YYYY-MM-DD');

        // this.dashData.fromDate = this.daterange.start;
        // this.dashData.toDate = this.daterange.end;

        // this.apiCallCount = 0;
        // this.pager["total_count"] = 0;
        // this.pager["current_page"] = 1;
        // this.pager["offset"] = 0;
        // this.offSet = 0;
        // this.listData = [];

        // this.localData.fromDate = this.daterange.start;
        // this.localData.toDate = this.daterange.end;

        // this.getSocialMediaData(this.dashData,1);

        // this.lastDate = this.daterange.end;       
      }      

       //-----------------------------Single and double click handling----------------------------
      setHighlightRow(index:number,id:number,highlightStatus:boolean) {
        console.log(index+'-'+id+'-'+highlightStatus)
        this.isSingleClick = true;        
        setTimeout(()=>{                            
                 if(this.isSingleClick){                                         
                    this.setHighlight(index,id,highlightStatus)
                 }              
        },500)
      }
      denyHighlight(){
              this.isSingleClick = false;                                          
      }          
      //----------------------------------------Highlight Update function----------------------------------------
      setHighlight(index:number,id:number,highlightStatus:boolean) {              
        let updatedStatus:boolean;         
        let updateData = {}; 

        if(highlightStatus) 
        updatedStatus = false;
        else
        updatedStatus = true;
        
        updateData['platform'] = 'social_media';
        updateData['id'] = id;
        updateData['updatedStatus'] = updatedStatus;
        
          //Upadte highlight status       
          this.loaderService.display(true);          
          this.dashboardService.updateHighlightStatus(updateData).subscribe((res:any)=>{            

            // if(JSON.parse(res._body).status == "success") {             
            if(res.status == 200) {    
              let highlightRowIndex = this.listData.findIndex(x => x.id ===id);                 
              this.listData[highlightRowIndex].highlight_status = updatedStatus;                
              this.loaderService.display(false);            
            }
            else {             
              this.loaderService.display(false);    
              // if(res.json().message=="invalid_accesstoken"||res.json().message=="un_authorised"){
                if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
                //this.toastr.error(this.errorDscrp,this.errorMsg);
                this.authServiceObj.logout(true);
              }
            }               
          });
       }       
      //-----------------------------------------------------------------------------------------------


      exportToCsv(){
        //Copy to variable to avoid original data mutation
        const smData = this.listData;

        //Format the data to avoid unwanted fields
        var keyMap = {
          // detection_date : 'DetectionDate',
          // brand : 'Brand',
          // product_name : 'ProductName',
          // keyword : 'Keyword',
          // product_category : 'ProductCategory',
          // social_media : 'SocialMedia',
          // country : 'Country',
          // price : 'Price',
          // currency : 'Currency',
          // item_number : 'ItemNumber',
          // url_profile : 'UrlProfile',
          // url : 'URL',
          // status : 'Status',
          // likes : 'Likes',
          // in_stock : 'InStock',
          // type_of_infringement : 'TypeOfInfringement',
          // seller_id: 'SellerId',
          // seller_name: 'SellerName',
          // phone_number_1: 'PhoneNumber1',
          // phone_number_2: 'PhoneNumber2',
          // email_address_1: 'EmailAddress1',
          // email_address_2: 'EmailAddress2',
          // address: 'Address'
          detection_date : 'DetectionDate',          
          brand : 'Brand',
          product_category : 'ProductCategory',
          product_name : 'ProductName',
          keyword : 'Keyword',
          social_media: 'MarketPlace',
          mother_company : 'MotherComapany',
          country : 'Country',
          url_profile : 'UrlProfile',
          url : 'URL',
          status : 'Status',
          type_of_infringement : 'TypeOfInfringement',
          likes : 'Likes',
          price : 'Price',
          currency : 'Currency',
          item_number : 'ItemNumber',
          in_stock : 'InStock',
          profile_name : 'ProfileName',
          owner_name : 'OwnerName',
          phone_number_1: 'PhoneNumber1',
          phone_number_2: 'PhoneNumber2',
          email_address_1: 'EmailAddress1',
          email_address_2: 'EmailAddress2',
          address: 'Address',
          social_network_link: 'SocialMediaLink',
          marketplace_link: 'MarketplaceLink',          
          standalone_website_link: 'StandaloneWebsiteLink',
          member_since:'MemberSince',
          location_product:'LocationProduct',
          ad_publication_date:'AdPublicationDate',
          followers:'Followers',
          subscriptions:'Subscriptions',
          profile_type:'ProfileType',
          living_at:'LivingAt',
          location:'Location',
          description:'Description',
          website:'Website',
          facebook:'Facebook',
          instagram:'Instagram',
          pinterest:'Pinterest',
          tiktok:'Tiktok',
          twitter:'Twitter',
          youtube:'Youtube',
          snapchat:'Snapchat',
          telegram:'Telegram',
          threads:'Threads',
          wechat:'Wechat',
          whatsapp:'Whatsapp',
          short_links:'ShortLinks',
          evaluation:'Evaluation' 
        };

        var excelData = smData.map((obj) => {
          return _.mapKeys(obj, (value, key) => {
            return keyMap[key];
          });
        });

        const excelDataModified = excelData.map((datum, i) => {
          return {
            // DetectionDate: datum.DetectionDate,
            // Brand: datum.Brand,
            // Product_Name: datum.ProductName,
            // Keyword: datum.Keyword,
            // Product_Category: datum.ProductCategory,
            // Social_Media: datum.SocialMedia,
            // Country: datum.Country,
            // Price: datum.Price,
            // Currency: datum.Currency,
            // Item_Number: datum.ItemNumber,
            // Url_Profile: datum.UrlProfile,
            // URL: datum.URL,
            // Status: datum.Status,
            // Likes: datum.Likes,
            // In_Stock: datum.InStock,
            // Type_Of_Infringement: datum.TypeOfInfringement,
            // Seller_Id : datum.SellerId ? datum.SellerId:'',
            // Seller_Name : datum.SellerName ? datum.SellerName:'',
            // Phone_Number_1 : datum.PhoneNumber1 ? datum.PhoneNumber1:'',
            // Phone_Number_2 : datum.PhoneNumber2 ? datum.PhoneNumber2:'',
            // Email_Address_1 : datum.EmailAddress1 ? datum.EmailAddress1:'',
            // Email_Address_2 : datum.EmailAddress2 ? datum.EmailAddress2:'',
            // Address : datum.Address ? datum.Address:''
            Detection_Date: this.datepipe.transform(datum.DetectionDate, 'dd/MM/yyyy'),
            Brand_Name: datum.Brand ? datum.Brand:'',
            Product_Category: datum.ProductCategory ? datum.ProductCategory:'',
            Product_Name: datum.ProductName ? datum.ProductName:'',
            Keyword: datum.Keyword ? datum.Keyword:'',
            Marketplaces: datum.MarketPlace ? datum.MarketPlace:'',  
            Parent_Company: datum.MotherComapany ? datum.MotherComapany:'',
            Country: datum.Country ? datum.Country:'',
            URL_Profile: datum.UrlProfile ? datum.UrlProfile:'',
            URL: datum.URL ? datum.URL:'',
            Status: datum.Status ? datum.Status:'',
            Type_Of_Infringement: datum.TypeOfInfringement ? datum.TypeOfInfringement:'',
            Likes: datum.Likes ? datum.Likes:'',
            Price: datum.Price ? datum.Price:'',
            Currency: datum.Currency ? datum.Currency:'',
            Item_Number: datum.ItemNumber ? datum.ItemNumber:'',
            Item_Available: datum.InStock ? datum.InStock:'',
            Profile_Name: datum.ProfileName ? datum.ProfileName:'',
            Owner_Name: datum.OwnerName ? datum.OwnerName:'',
            Phone_Number_1 : datum.PhoneNumber1 ? datum.PhoneNumber1:'',
            Phone_Number_2 : datum.PhoneNumber2 ? datum.PhoneNumber2:'',
            Email_Address_1 : datum.EmailAddress1 ? datum.EmailAddress1:'',
            Email_Address_2 : datum.EmailAddress2 ? datum.EmailAddress2:'',
            Address : datum.Address ? datum.Address:'',
            Social_Network_Link : datum.SocialMediaLink ? datum.SocailMediaLink:'',
            Marketplace_Link : datum.MarketplaceLink ? datum.MarketplaceLink:'',            
            Standalone_Website_Link : datum.StandaloneWebsiteLink ? datum.StandaloneWebsiteLink:'',
            Member_Since: datum.MemberSince ? datum.MemberSince : '',
            Location_Product: datum.LocationProduct? datum.LocationProduct :'',
            Ad_Publication_Date: datum.AdPublicationDate?datum.AdPublicationDate:'',
            Followers: datum.Followers?datum.Followers:'',
            Subscriptions: datum.Subscriptions?datum.Subscriptions:'',
            Profile_Type: datum.ProfileType?datum.ProfileType:'',
            Living_At: datum.LivingAt?datum.LivingAt:'',
            Location: datum.Location?datum.Location:'',
            Description: datum.Description?datum.Description:'',
            Website: datum.Website?datum.Website:'',
            Facebook: datum.Facebook?datum.Facebook:'',
            Instagram: datum.Instagram?datum.Instagram:'',
            Pinterest: datum.Pinterest?datum.Pinterest:'',
            Tiktok: datum.Tiktok?datum.Tiktok:'',
            Twitter: datum.Twitter?datum.Twitter:'',
            Youtube: datum.Youtube?datum.Youtube:'',
            Telegram: datum.Telegram?datum.Telegram:'',
            'threads.net' : datum.Threads? datum.Threads : '',
            Wechat: datum.Wechat?datum.Wechat:'',
            Short_Links: datum.ShortLinks?datum.ShortLinks:'',
            Evaluation: datum.Evaluation?datum.Evaluation:'',
          }
        });
        const months = ["January", "February", "March","April", "May", "June", "July", "August", "September", "October", "November", "December"];
          let current_datetime = new Date(this.localData.toDate)
          let title_month = months[current_datetime.getMonth()];
          let title_year  = current_datetime.getFullYear();
          
        //Export
        // new Angular2Csv(excelDataModified, 'Social_Media_'+this.breakdown.company+'Report',{ headers: Object.keys(excelDataModified[0])});
        //  new Angular2Csv(excelDataModified, this.listData[0].brand+'-Social Networks-'+title_month+' '+title_year,{ headers: Object.keys(excelDataModified[0])});
         
        //---File name must be 'All' when multiple files downloaded. Otherwise file name must be the brand name
        var activeBrands = this.lsService.retrieve('active_brand_id');
        if((activeBrands.length)>1) {
          new Angular2Csv(excelDataModified, 'All-Social Media-'+title_month+' '+title_year,{ headers: Object.keys(excelDataModified[0])});
        } else {
          new Angular2Csv(excelDataModified, this.listData[0].brand+'-Social Networks-'+title_month+' '+title_year,{ headers: Object.keys(excelDataModified[0])});
        }
      }

      print() {
        const months = ["January", "February", "March","April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let current_datetime = new Date(this.localData.toDate)
        let title_month = months[current_datetime.getMonth()];
        let title_year  = current_datetime.getFullYear();
        
        //---File name must be 'All' when multiple files downloaded. Otherwise file name must be the brand name
        var activeBrands = this.lsService.retrieve('active_brand_id');
        if((activeBrands.length)>1) {
          var printTitle = 'All-Social Networks-'+title_month+' '+title_year;
        } else {
          var printTitle = this.listData[0].brand+'-Social Networks-'+title_month+' '+title_year;
        }

        let newWindow = window.open('', '', 'width=800, height=500'),
        document = newWindow.document.open(),
        pageContent =
        '<!DOCTYPE html>' +
        '<html>' +
        '<head>' +
        '<meta charset="utf-8" />' +
        // '<title>Social-Media-'+this.breakdown.company+'</title>' +
        // '<title>'+this.listData[0].brand+'-Social Networks-'+title_month+' '+title_year+'</title>' +       
        '<title>'+printTitle+'</title>' +       
        '<style>table {border-collapse: collapse;} table, td, th { border: 1px solid black;}</style>'+
        '</head>' +
        '<body>' + '<table>'+
        '<thead>'+
        '<tr>'+
        '<th class="text-center">Detection Date </th>'+
        '<th >Brand </th>'+
        '<th >Product Name </th>'+
        '<th >Keyword </th>'+
        '<th >Product Category </th>'+
        '<th >Social Media </th>'+
        '<th >Country </th>'+
        '<th >Price </th>'+
        '<th >Currency </th>'+
        '<th >Item Number </th>'+
        // '<th >URL Profile </th>'+
        // '<th >URL </th>'+
        '<th >Status </th>'+
        '<th >Likes </th>'+
        '<th >Item Available(in stock) </th>'+
        '<th >Type of infringement </th>'+
        '<th > Seller Id </th>'+
        '<th > Seller Name </th>'+
        '<th > Phone Number #1 </th>'+
        '<th > Phone Number #2 </th>'+
        '<th > Email Address #1 </th>'+
        '<th > Email Address #2 </th>'+
        '<th > Address </th>'+
        '</tr>'+
        '</thead>'+
        '<tbody>';
        for(let i = 0; i< this.listData.length; i++){
          pageContent = pageContent + '<tr>'+
          '<td>'+(this.datepipe.transform(this.listData[i].detection_date, 'dd/MM/yyyy'))+'</td>'+
          '<td>'+this.listData[i].brand+'</td>'+
          '<td>'+this.listData[i].product_name+'</td>'+
          '<td>'+this.listData[i].keyword+'</td>'+
          '<td>'+this.listData[i].product_category+'</td>'+
          '<td>'+this.listData[i].market_place+'</td>'+
          '<td>'+this.listData[i].country+'</td>'+
          '<td class="text-right">'+this.listData[i].price+'</td>'+
          '<td>'+this.listData[i].currency+'</td>'+
          '<td class="text-right">'+this.listData[i].item_number+'</td>'+
          // '<td>'+this.listData[i].url_profile+'</td>'+
          // '<td>'+this.listData[i].url+'</td>'+
          '<td>'+this.listData[i].status+'</td>'+
          '<td class="text-right">'+this.listData[i].likes+'</td>'+
          '<td>'+this.listData[i].in_stock+'</td>'+
          '<td>'+this.listData[i].type_of_infringement+'</td>'+
          '<td>'+this.listData[i].seller_id+'</td>'+
          '<td>'+this.listData[i].seller_name+'</td>'+
          '<td>'+this.listData[i].phone_number_1+'</td>'+
          '<td>'+this.listData[i].phone_number_2+'</td>'+
          '<td>'+this.listData[i].email_address_1+'</td>'+
          '<td>'+this.listData[i].email_address_2+'</td>'+
          '<td>'+this.listData[i].address+'</td>'+
          '</tr>'

        }

        pageContent = pageContent+ '</tbody>'+
        '</table>' + 
        '</body></html>';
        try {
          document.write(pageContent);
          document.close();
          newWindow.print();
          newWindow.close();
        }
        catch (error) {
        }
      }

      copyToClipboard(){
        let selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        this.copyValue = 'Detection date, Brand, Product Name, Product Category, Social Media, Country, Price, Currency, Url Profile,  Item Number, Status, Likes, Item Available, Type of Infringement, Seller Id, Seller Name, Phone Number #1, Phone Number #2, Email Address #1, Email Address #2, Address \n';
        for( let i = 0; i < this.listData.length; i++ ){
          this.copyValue = this.copyValue + ( 
            //  (this.datepipe.transform(this.listData[i].detection_date, 'dd/MM/yyyy')) + ', ' + 
            this.listData[i].detection_date + ', ' + 
            this.listData[i].brand + ', ' + 
            this.listData[i].product_name + ', ' + 
            //  this.listData[i].keyword + ', ' + 
            this.listData[i].product_category + ', ' + 
            this.listData[i].social_media + ', ' + this.listData[i].country + ', ' + this.listData[i].price + ', ' +
            this.listData[i].currency + ', ' + this.listData[i].item_number + ', ' + 
            this.listData[i].url_profile + ', ' +
            //   this.listData[i].url + ', ' + 
            this.listData[i].status + ', ' +
            this.listData[i].likes + ', ' +
            this.listData[i].in_stock + ', ' + this.listData[i].type_of_infringement + ', ' +
            this.listData[i].seller_id + ', '+this.listData[i].seller_name + ', '+this.listData[i].phone_number_1 + ', '+
            this.listData[i].phone_number_2 + ', '+this.listData[i].email_address_1 + ', '+this.listData[i].email_address_2 + ', '+
            this.listData[i].address + ', ' + ' \n') ;            
        }
        selBox.value = this.copyValue;
        document.body.appendChild(selBox);    
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.toastr.success('Data copied to clipboard');
      }

      openModal(template: TemplateRef<any>, data) {
        this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
        this.screenShots = data;
      }
      openModal2(template: TemplateRef<any>, id: number) {
        setTimeout(() => { this.activeIndex = (id); }, 0);
        this.modalRef2 = this.modalService.show(template, { class: 'second' });
      } 

      //two scroll

      // scroll() {
      //  this.table.nativeElement.scrollTo(this.scroller.nativeElement.scrollLeft,0)
      // }

      // scroll1() {
      //   this.scroller.nativeElement.scrollTo(this.table.nativeElement.scrollLeft,0)
      //  }

       //export to xlsx

      fireEvent()
      {
           //Copy to variable to avoid original data mutation
        const smData = this.listData;
        
        //Format the data to avoid unwanted fields        
        var keyMap = {
          
          detection_date : 'DetectionDate',          
          brand : 'Brand',
          product_category : 'ProductCategory',
          product_name : 'ProductName',
          keyword : 'Keyword',
          social_media: 'MarketPlace',
          mother_company : 'MotherComapany',
          country : 'Country',
          url_profile : 'UrlProfile',
          url : 'URL',
          status : 'Status',
          type_of_infringement : 'TypeOfInfringement',
          likes : 'Likes',
          price : 'Price',
          currency : 'Currency',
          item_number : 'ItemNumber',
          in_stock : 'InStock',
          profile_name : 'ProfileName',
          owner_name : 'OwnerName',
          phone_number_1: 'PhoneNumber1',
          phone_number_2: 'PhoneNumber2',
          email_address_1: 'EmailAddress1',
          email_address_2: 'EmailAddress2',
          address: 'Address',
          social_network_link: 'SocialMediaLink',
          marketplace_link: 'MarketplaceLink',          
          standalone_website_link: 'StandaloneWebsiteLink',
          member_since:'MemberSince',
          location_product:'LocationProduct',
          ad_publication_date:'AdPublicationDate',
          followers:'Followers',
          subscriptions:'Subscriptions',
          profile_type: 'ProfileType',
          living_at: 'LivingAt',
          location: 'Location',
          description: 'Description',
          website: 'Website',
          facebook:'Facebook',
          instagram:'Instagram',
          pinterest:'Pinterest',
          tiktok:'Tiktok',
          twitter:'Twitter',
          youtube:'Youtube',
          snapchat:'Snapchat',
          telegram:'Telegram',
          threads:'Threads',
          wechat:'Wechat',
          whatsapp:'Whatsapp',
          short_links:'ShortLinks',
          evaluation:'Evaluation'
        };

        var excelData = smData.map((obj) => {
          return _.mapKeys(obj, (value, key) => {
            return keyMap[key];
          });
        });

        const excelDataModified = excelData.map((datum, i) => {      
          return {
            // Detection_Date: datum.DetectionDate,
            // Detection_Date: this.datepipe.transform(datum.DetectionDate, 'yyyy/dd/MM'),
            Detection_Date: this.datepipe.transform(datum.DetectionDate, 'dd/MM/yyyy'),
            Brand_Name: datum.Brand ? datum.Brand:'',
            Product_Category: datum.ProductCategory ? datum.ProductCategory:'',
            Product_Name: datum.ProductName ? datum.ProductName:'',
            Keyword: datum.Keyword ? datum.Keyword:'',
            Marketplaces: datum.MarketPlace ? datum.MarketPlace:'',  
            Parent_Company: datum.MotherComapany ? datum.MotherComapany:'',
            Country: datum.Country ? datum.Country:'',
            URL_Profile: datum.UrlProfile ? datum.UrlProfile:'',
            URL: datum.URL ? datum.URL:'',
            Status: datum.Status ? datum.Status:'',
            Type_Of_Infringement: datum.TypeOfInfringement ? datum.TypeOfInfringement:'',
            Likes: datum.Likes ? datum.Likes:'',
            Price: datum.Price ? datum.Price:'',
            Currency: datum.Currency ? datum.Currency:'',
            Item_Number: datum.ItemNumber ? datum.ItemNumber:'',
            Item_Available: datum.InStock ? datum.InStock:'',
            Profile_Name: datum.ProfileName ? datum.ProfileName:'',
            Owner_Name: datum.OwnerName ? datum.OwnerName:'',
            Phone_Number_1 : datum.PhoneNumber1 ? datum.PhoneNumber1:'',
            Phone_Number_2 : datum.PhoneNumber2 ? datum.PhoneNumber2:'',
            Email_Address_1 : datum.EmailAddress1 ? datum.EmailAddress1:'',
            Email_Address_2 : datum.EmailAddress2 ? datum.EmailAddress2:'',
            Address : datum.Address ? datum.Address:'',
            Social_Network_Link : datum.SocialMediaLink ? datum.SocialMediaLink:'',
            Marketplace_Link : datum.MarketplaceLink ? datum.MarketplaceLink:'',            
            Standalone_Website_Link : datum.StandaloneWebsiteLink ? datum.StandaloneWebsiteLink:'',
            Member_Since: datum.MemberSince ? datum.MemberSince : '',
            Location_Product: datum.LocationProduct? datum.LocationProduct :'',
            Ad_Publication_Date: datum.AdPublicationDate?datum.AdPublicationDate:'',
            Followers: datum.Followers?datum.Followers:'',
            Subscriptions: datum.Subscriptions?datum.Subscriptions:'',
            Profile_Type: datum.ProfileType?datum.ProfileType:'',
            Living_At: datum.LivingAt?datum.LivingAt:'',
            Location: datum.Location?datum.Location:'',
            Description: datum.Description?datum.Description:'',
            Website: datum.Website?datum.Website:'',
            Facebook: datum.Facebook?datum.Facebook:'',
            Instagram: datum.Instagram?datum.Instagram:'',
            Pinterest: datum.Pinterest?datum.Pinterest:'',
            Tiktok: datum.Tiktok?datum.Tiktok:'',
            Twitter: datum.Twitter?datum.Twitter:'',
            Youtube: datum.Youtube?datum.Youtube:'',
            Telegram: datum.Telegram?datum.Telegram:'',
            'threads.net' : datum.Threads? datum.Threads : '',
            Wechat: datum.Wechat?datum.Wechat:'',
            Short_Links: datum.ShortLinks?datum.ShortLinks:'',
            Evaluation: datum.Evaluation?datum.Evaluation:'',
          }
        });
        // const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement);
        // const wb: XLSX.WorkBook = XLSX.utils.book_new();
        // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        
        // /* save to file */
        // XLSX.writeFile(wb,'Standalone_'+this.countryName+'Report.xlsx');

        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelDataModified);
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };        
        // XLSX.writeFile(workbook, 'Social_Media_'+this.breakdown.company+'Report.xlsx', { bookType: 'xlsx', type: 'buffer' });
        
          const months = ["January", "February", "March","April", "May", "June", "July", "August", "September", "October", "November", "December"];
          let current_datetime = new Date(this.localData.toDate)
          let title_month = months[current_datetime.getMonth()];
          let title_year  = current_datetime.getFullYear();
        // XLSX.writeFile(workbook, this.listData[0].brand+'-Social Networks-'+title_month+' '+title_year+'.xlsx', { bookType: 'xlsx', type: 'buffer' });
        
        //---File name must be 'All' when multiple files downloaded. Otherwise file name must be the brand name
        var activeBrands = this.lsService.retrieve('active_brand_id');
        if((activeBrands.length)>1) {
          XLSX.writeFile(workbook, 'All-Social Networks-'+title_month+' '+title_year+'.xlsx', { bookType: 'xlsx', type: 'buffer' });
        } else {
          XLSX.writeFile(workbook, this.listData[0].brand+'-Social Networks-'+title_month+' '+title_year+'.xlsx', { bookType: 'xlsx', type: 'buffer' });
        }
      }



    }
