import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Http, RequestOptions } from '@angular/http';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { AppSettings } from 'src/app/settings.global';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private apiUrl = AppSettings.AI_CHAT_URL;

  constructor(private http: HttpClient) { }

  getChatResponse(userRole: string, userQuestion: string, userId:number, saveHistory:any): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json;charset=UTF-8');
    headers.append('Accept', 'application/json');
    const body = {
      user_role: userRole,
      user_question: userQuestion,
      user_id:userId,
      history:saveHistory
    };

    return this.http.post(this.apiUrl, body,{headers});
  }
}