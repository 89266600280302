import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ChatService } from '../../services/chat/chat.service';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-ai-chat',
  templateUrl: './ai-chat.component.html',
  styleUrls: ['./ai-chat.component.scss'],
  providers: [ChatService]
})
export class AiChatComponent implements OnInit {
  isChatVisible = false;
  userQuestion: string = '';
  chatHistory: any[] = [];
  userRole: string = '';
  maxStorageSize: number = 4.5 * 1024 * 1024; 
  isTyping: boolean = false;
  userId: number | null = null;

  @ViewChild('chatMessages') private chatMessagesContainer!: ElementRef;

  constructor(
    private chatService: ChatService,
    private lsService: LocalStorageService) { 
    this.loadUserRole();
    this.loadChatHistory();
  }

  ngOnInit() {
    if (this.chatHistory.length === 0) {
      this.chatHistory.push({
        user: 'AI',
        answer: 'Hello, how can I help you?',
        timestamp: new Date()
      });
      this.saveChatHistory();
    }
  }

  toggleChat() {
    this.isChatVisible = !this.isChatVisible;
    setTimeout(()=> this.scrollToBottom(),0);
  }
  
  loadUserRole() {
    const userDetails = this.lsService.retrieve('web_user_details');
    if (userDetails) {
      const userRole = userDetails.user_role;
      this.userId = userDetails?userDetails.id: null;
      
      switch(userRole) {
        case 1:
          this.userRole = 'super_admin';
          break;
        case 2:
          this.userRole = 'user';
          break;
        case 3:
          this.userRole = 'admin';
          break;
      }
    }
  }

  sendMessage() {
    if (this.userQuestion.trim()) {
      this.chatHistory.push({
        user: 'User',
        question: this.userQuestion, 
        timestamp: new Date()
      });
      this.isTyping = true;
      setTimeout(()=> this.scrollToBottom(),0);

      this.chatService.getChatResponse(this.userRole, this.userQuestion, this.userId).subscribe(
        (response) => {
          this.isTyping = false;
          if (response.response_answer.includes('##NOANSWER')) {
            // Display the predefined questions when no data is available
            const noDataMessage = `
            Sorry, I couldn't find the data.<br>
            Please provide the name of the platform (Marketplaces/Social Media/Standalone),
            the date range (From date and To date),
            and the brand name for which you are seeking data in the question.
            <br>For example, 
            <br>What is the most repeated Infringement type in Social Media from January 2024 to current date?
          `;
            
            // Add AI's response and the predefined questions to the chat history
            this.chatHistory.push({
              user: 'AI',
              answer: noDataMessage,
              timestamp: new Date()
            });

          } else {
            this.chatHistory.push({ 
              user: 'AI', 
              answer: response.response_answer, 
              timestamp: new Date()
            });
          }
            this.saveChatHistory();
            this.scrollToBottom();
        },
        (error) => {
          this.isTyping = false;
          console.error('Error getting chat response:', error);
          this.chatHistory.push({ 
            user: 'AI', 
            answer: 'Sorry, I encountered an error. Please try again later.', 
            timestamp: new Date()
          });
          this.saveChatHistory();
          this.scrollToBottom();
        }
      );

      this.userQuestion = '';
      this.isTyping = true;
    }
  }

  loadChatHistory() {
    const savedHistory = this.lsService.retrieve('chatHistory');
    this.chatHistory = savedHistory ? JSON.parse(savedHistory) : [];
  }

  saveChatHistory() {
    
    while (this.getStorageSize() > this.maxStorageSize && this.chatHistory.length > 1) {
      this.chatHistory.shift();
    }
    this.lsService.store('chatHistory', JSON.stringify(this.chatHistory));
  }

  getStorageSize(): number {
    let totalSize = 0;
    for (let key in this.lsService) {
      if (this.lsService.hasOwnProperty(key)) {
        totalSize += this.lsService[key].length * 2;
      }
    }
    return totalSize;
  }

  scrollToBottom() {
    try {
      setTimeout(() => {
        this.chatMessagesContainer.nativeElement.scrollTop = this.chatMessagesContainer.nativeElement.scrollHeight;
      }, 0);
    } catch (err) {
      console.error('Error while scrolling to the bottom:', err);
    }
  }
}