import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Loader } from '@googlemaps/js-api-loader';
import { debounceTime } from 'rxjs/operators';
import MarkerClusterer from '@googlemaps/markerclustererplus';
import { DataAnalysisService } from '../../../services/data-analysis/data-analysis.service';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from '../../../services/loader/loader.service';
import { AuthService } from '../../../services/auth/auth.service';
import { LocalStorageService } from 'ngx-webstorage';
import { environment } from '../../../../environments/environment';

interface SellerGroup {
  position: google.maps.LatLng;
  sellers: any[];
}

@Component({
  selector: 'app-seller-map',
  templateUrl: './seller-map.component.html',
  styleUrls: ['./seller-map.component.scss']
})
export class SellerMapComponent implements OnInit,OnChanges {
  private map: google.maps.Map;
  private markerClusterer: MarkerClusterer;
  private geocoder: google.maps.Geocoder;
  private request;
  public source: string;
  public sellerData: any;
  private readonly OFFSET_DISTANCE = 0.0001; 
  @Input('fromDate')fromDate:string;
  @Input('toDate')toDate:string;

  constructor(private dataAnalysis:DataAnalysisService,
    private toastr: ToastrService,
		private loaderService: LoaderService,
    private authServiceObj: AuthService,
    public localSt: LocalStorageService,
  ) {
    this.request = {
      brand_id : this.localSt.retrieve('active_brand_id'),
      fromDate : this.fromDate,
      toDate : this.toDate
    };
  }
  ngOnInit() {
    this.request = {
      brand_id: this.localSt.retrieve('active_brand_id'),
      fromDate: this.fromDate,
      toDate: this.toDate,
    };
    if (this.fromDate && this.toDate) {
      this.getSellerData();
    }
  }

  

  private initMap(): void {
    const loader = new Loader({
      apiKey: environment.googleMapsApiKey
    });

    loader.load().then(() => {
      const mapOptions: google.maps.MapOptions = {
        center: { lat: 0, lng: 0 },
        zoom: 2,
        clickableIcons: false
      };

      this.map = new google.maps.Map(document.getElementById('map') as HTMLElement, mapOptions);
      this.geocoder = new google.maps.Geocoder();

      const idleStream = new Observable(observer => {
        this.map.addListener('idle', () => observer.next());
      }).pipe(debounceTime(250));

      idleStream.subscribe(() => this.addMarkers());
    });
  }

  private parseCoordinates(coordString: string): [number, number] | null {
    const regex = /\((-?\d+\.\d+),\s*(-?\d+\.\d+)\)/;
    const matches = coordString.match(regex);
  
    if (matches) {
      const lng = parseFloat(matches[1]);
      const lat = parseFloat(matches[2]);
      return [lng, lat];
    } else {
      console.warn(`Invalid coordinate format: ${coordString}`);
      return null;
    }
  }

  private groupSellersByLocation(): SellerGroup[] {
    const groups: Map<string, SellerGroup> = new Map();
    
    this.sellerData && this.sellerData.length && this.sellerData.forEach(seller => {
      const coords = this.parseCoordinates(seller.coordinates);
      if (coords) {
        const [lng, lat] = coords;
        const key = `${lat},${lng}`;
        
        if (!groups.has(key)) {
          groups.set(key, {
            position: new google.maps.LatLng(lat, lng),
            sellers: []
          });
        }
        groups.get(key) && groups.get(key).sellers.push(seller);
      }
    });

    return Array.from(groups.values());
  }

  private createInfoWindowContent(sellers: any[]): string {
    return `
      <div class="info-window">
        ${sellers.map(seller => `
          <div class="seller-item ${sellers.length > 1 ? 'multiple' : ''}">
            <div class="info-title">
              ${seller.seller_name}
            </div>
            <div class="info-row">
              <i class="fas fa-envelope"></i>
              <span>${seller.email_1 ? seller.email_1 : (seller.email_2 ? seller.email_2 : 'N/A')}</span>
            </div>
            <div class="info-row">
              <i class="fas fa-map-marker-alt"></i>
              <span>${seller.address ? seller.address : 'N/A'}</span>
            </di?v>
          </div>
          ${sellers.length > 1 && sellers.indexOf(seller) !== sellers.length - 1 ? '<hr class="seller-divider">' : ''}
        `).join('')}
      </div>
    `;
  }

  private offsetPosition(position: google.maps.LatLng, index: number, total: number): google.maps.LatLng {
    if (total <= 1) return position;
    
    // Create a circular arrangement for multiple markers
    const angle = (2 * Math.PI * index) / total;
    const newLat = position.lat() + Math.cos(angle) * this.OFFSET_DISTANCE;
    const newLng = position.lng() + Math.sin(angle) * this.OFFSET_DISTANCE;
    
    return new google.maps.LatLng(newLat, newLng);
  }

  private addMarkers(): void {
    if (this.markerClusterer) {
      this.markerClusterer.clearMarkers();
    }

    const sellerGroups = this.groupSellersByLocation();
    const markers: google.maps.Marker[] = [];

    sellerGroups.forEach(group => {
      const { position, sellers } = group;
      
      sellers.forEach((seller, index) => {
        // Offset the marker position if there are multiple sellers
        const offsetPos = this.offsetPosition(position, index, sellers.length);
        
        const marker = new google.maps.Marker({
          position: offsetPos,
          map: this.map,
          title: seller.seller_name
        });

        const infoWindow = new google.maps.InfoWindow({
          content: this.createInfoWindowContent(sellers),
          pixelOffset: new google.maps.Size(0, -5)
        });

        marker.addListener('click', () => {
          // Close any open info windows first
          this.closeAllInfoWindows();
          infoWindow.open(this.map, marker);
        });

        markers.push(marker);
      });
    });

    this.markerClusterer = new MarkerClusterer(this.map, markers, {
      imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
      maxZoom: 15, // Adjust this value based on when you want clustering to stop
      minimumClusterSize: 2
    });
  }
  private closeAllInfoWindows(): void {
    // Implement this method to close any open info windows
    // You'll need to maintain a reference to open info windows
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fromDate || changes.toDate) {
      if (changes.fromDate.currentValue && changes.toDate.currentValue) {
        this.request.fromDate = changes.fromDate.currentValue;
        this.request.toDate = changes.toDate.currentValue;
        this.getSellerData();
      }
    }
  }
  


  public getSellerData() {
    if (this.markerClusterer) {
      this.markerClusterer.clearMarkers();
    }
    this.loaderService.display(true);
    this.dataAnalysis.getSellersWithCordinates(this.request)
      .subscribe(
        res => {
          this.loaderService.display(false);
          console.log(res);
          
          if (res.status === 'success') {
            this.sellerData = res.seller_list;
            console.log(this.sellerData, "seller data");
            this.initMap();
            
            this.addMarkers(); // Update markers without re-initializing the map
          } else {
            if (res.message === "invalid_accesstoken" || res.message === "un_authorised") {
              this.toastr.error("Kindly login", "Session Expired");
              this.authServiceObj.logout(true);
            }
          }
        },
        error => {
          this.loaderService.display(false);
          this.toastr.error("Failed to load seller data", "Error");
        }
      );
  }

}