import { Injectable } from '@angular/core';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Http, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/operator/map';
import { AppSettings } from '../../settings.global';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthService {
  public headers: Headers;
  public deviceid: any;
  public push_token: any;
  public device_type: any;
  public login_type: any;
  public type: any;
  constructor(public http: Http, public localSt: LocalStorageService,
   public router: Router,public toastr:ToastrService) {
    // The http object and the header is set
    this.headers = new Headers();
    this.headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8');
    // Devise details
    this.deviceid = 'mydevice';
    this.push_token = 'demo';
    this.device_type = 'web';
    this.type = 'web';
  }

  /*
   * authenticate user
   */

  authenticate(body: any) {

    const bodyString: string = 'email=' + body.email +
                                '&password=' + body.password +
                                '&device_type=' + this.device_type +
                                '&device_id=' + this.deviceid +
                                '&push_token=' + this.push_token +
                                '&type=' + this.type;
    /*
     * Requst for login
     */
    return this.http.post(AppSettings.API_ENDPOINT + '/auth/login', bodyString, { headers: this.headers })
      .map(function (res: any) {
        return res.json() || { status: 'failed', message: 'Somthing went wrong' };
      });
  }

  // Forgot password
  forgotPassword(body: any) {
    const bodyString: string = 'email=' + body.email + '&type=' + this.type;
    const headers = new Headers();
    this.headers.append('Accept', 'application/json');
    const options = new RequestOptions({ headers: this.headers });

    return this.http.post(AppSettings.API_ENDPOINT + '/auth/forgot-password', bodyString, options)
      .map(function (res: any) {
        return res.json() || { status: 'failed', message: 'Somthing went wrong' };
      });
  }

  // Change password
  changePassword(body: any) {
    const headers = new Headers();
    this.headers.set('x-access-token', this.localSt.retrieve('web_access_token'));
    this.headers.append('Accept', 'application/json');
    const options = new RequestOptions({ headers: this.headers });
    const bodyString: string = 'cur_password=' + body.cur_password + '&new_password=' + body.new_password + '&id=' + body.id;

    return this.http.post(AppSettings.API_ENDPOINT + '/auth/change-password', bodyString, options)
      .map(function (res: any) {
        return res.json() || { status: 'faild', message: 'Somthing went wrong' };
      });
  }

  // Logout
  logout(value:boolean) {
    this.localSt.clear('web_access_token');
    this.localSt.clear('web_user_details');
    this.localSt.clear('search_base');
    this.localSt.clear('active_client_id');
    this.localSt.clear('active_brand_Id');
    this.localSt.clear('top_social_media');
    this.localSt.clear('top_market_place');
    this.localSt.clear('top_standalone_website');
    this.localSt.clear('top_standalone_website_name');
    this.localSt.clear('top_market_place_sub');
    this.localSt.clear('top_social_media_sub');
    this.localSt.clear('selectedbrands');
    this.localSt.clear('chatHistory');
    if(value==true){
      this.toastr.error("Kindly login","Session Expired");
    }
    

    this.router.navigate(['/login']);
    //this.router.navigate(['/']);
    //window.location.reload(true);
    return true;
  }
}
